import styled from "@emotion/styled";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Grid } from "@mui/material";

export const StyledAccordionSummary = styled(AccordionSummary)({
	padding: "0 16px",
	backgroundColor: "var(--accepted-card-background)",
	height: "auto",
	display: "-webkit-box",
	unicodeBidi: "isolate"
});

export const StyledOrderAndShippingIcon = styled(Grid)({
	img: {
		height: "26px",
		width: "42px"
	}
});

export const BackorderAndShipmentTextCount = styled.div(({ theme }) => ({
	height: "32px",
	fontSize: "inherit",
	minWidth: "204px",
	display: "inline-flex",
	div: {
		margin: "0 20px 0 16px",
		...theme.project.typography.subtitle.subtitle1,
		fontSize: 20,
		lineHeight: "28px",
		letterSpacing: "0.15px"
	}
}));

export const StyledAccordionSummaryContainer = styled.div(({ theme }) => ({
	padding: "0 16px",
	[theme.breakpoints.up("lg")]: {
		display: "flex"
	}
}));

export const StyledOrderAndShippingStatusMessage = styled.div(({ theme }) => ({
	display: "inline-flex",
	[theme.breakpoints.down("sm")]: {
		display: "block"
	}
}));

export const StyledOrderAndShippingStatus = styled(Grid)({
	maxWidth: "min-content",
	alignItems: "center",
	paddingTop: "2px"
});

export const StyledOrderAndShippingMessage = styled.div(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		marginLeft: "0rem"
	},
	alignItems: "center",
	paddingTop: "5px",
	marginLeft: "1rem"
}));
