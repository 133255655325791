import {
	NewOrdersLayoutContainer,
	NewOrdersContentContainer,
	NewOrderFlowButtons,
	NewOrdersContent
} from "components/NewOrders/NewOrders.styles";
import { NewOrderBack, NewOrderReviewPageTitle, NewOrderSubmitOrder } from "components/NewOrders/constants";
import NewOrdersSubheader from "components/NewOrders/NewOrdersSubheader/NewOrdersSubheader";
import ReviewOrderDetails from "components/NewOrders/Review/ReviewOrderDetails";
import ReviewOrderList from "components/NewOrders/Review/ReviewOrderList";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { type FC, RefObject, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import {
	CreatePendingOrderRequestPendingLineItemViewModel,
	CreatePendingOrderRequestPendingOrderConfigurationViewModel
} from "data/api/v1";
import {
	ViewPriceEstimatesBaseItem,
	ViewPriceEstimatesConfigurationModel
} from "components/NewOrders/ViewPriceEstimates/view-price-estimates-model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useCreateOrderMutation } from "features/newOrderApi";
import ErrorBanner from "components/Replacements/ErrorBanner";
import LoadingSkeleton from "pages/OrderPages/ReviewOrderPage/LoadingSkeleton/LoadingSkeleton";
import { ContactCareAndCheckDetailsErrorText } from "constants/text";
import { submittingNewOrderActions } from "features/submittingNewOrder";
import { useDeleteDraftOrderMutation } from "features/orderApi";

interface CombinedPendingOrderConfigurationViewModelLineItems {
	lineItems:
		| (
				| (CreatePendingOrderRequestPendingLineItemViewModel & {
						modifications: ViewPriceEstimatesBaseItem[] | undefined;
				  })[]
				| null
		  )
		| undefined;
}
type CombinedPendingOrderConfigurationViewModel = Omit<
	CreatePendingOrderRequestPendingOrderConfigurationViewModel,
	"lineItems"
> &
	CombinedPendingOrderConfigurationViewModelLineItems;
export interface NewOrdersForReview extends CombinedPendingOrderConfigurationViewModel {
	priceEstimateConfig: ViewPriceEstimatesConfigurationModel | undefined;
}

const ReviewOrderPage: FC = () => {
	const navigate = useNavigate();
	const [submitOrder, { error, isLoading }] = useCreateOrderMutation();
	const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [deleteDraftOrder] = useDeleteDraftOrderMutation();
	const draftId = useSelector((state: RootState) => state.submittingNewOrder.draftOrder?.draftOrderId);

	const newOrderDetails = useSelector((state: RootState) => state.submittingNewOrder.newOrder);

	const [contentOffset, setContentOffset] = useState(0);
	const calcHeightOfHeaderForContentTopOffset = (headerContainer: RefObject<HTMLDivElement>) => {
		setContentOffset(headerContainer.current?.clientHeight ?? 0);
	};

	const handleBack = () => {
		dispatch(submittingNewOrderActions.clearDraftError());
		navigate("/new-order/shipping-details");
	};

	const handleContinue = async () => {
		dispatch(submittingNewOrderActions.clearDraftError());
		setShowSkeleton(true);
		await submitOrder(newOrderDetails)
			.unwrap()
			.then(() => {
				navigate("/new-order/confirmation");
				deleteDraftOrder(draftId ?? "");
			})
			.catch(() => setShowSkeleton(false));
	};

	if (isLoading || showSkeleton) {
		return <LoadingSkeleton />;
	}

	return (
		<NewOrdersLayoutContainer>
			<NewOrdersHeader
				getContainerElement={calcHeightOfHeaderForContentTopOffset}
				isReview
				isPDFExport
			/>
			<NewOrdersContentContainer marginTopOffset={contentOffset}>
				<NewOrdersContent>
					<NewOrdersSubheader
						title={NewOrderReviewPageTitle}
						dataTestId="new-order-review-order-label"
						handleSearch={() => {}}
					/>
					{error && <ErrorBanner errorMessage={ContactCareAndCheckDetailsErrorText} />}
					<ReviewOrderDetails />
					<ReviewOrderList />
					<NewOrderFlowButtons>
						<Button
							data-testid="new-order-review-back-button"
							variant="text"
							onClick={handleBack}
						>
							{NewOrderBack}
						</Button>
						<Button
							data-testid="new-order-review-submit-order-button"
							onClick={handleContinue}
							variant="contained"
						>
							{NewOrderSubmitOrder}
						</Button>
					</NewOrderFlowButtons>
				</NewOrdersContent>
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default ReviewOrderPage;
