import { useEffect, useMemo, useState } from "react";
import { Button, Divider, Drawer } from "@mui/material";
import {
	AccountNumberText,
	DesignerNumberText,
	exportOrderDetailsText,
	myOrdersLinkText,
	OrderReplacementsText,
	ReplacementOrderSubmitted,
	ReplacementOrderSubmittedText,
	UnavailableDataPlaceholderText,
	EstimatedDeliveryText,
	OrdersText,
	NoLinkedOrdersHeaderText,
	EmptyLinkedOrdersHelperText,
	LinkedOrdersErrorHeaderText,
	ErrorLinkedOrdersHelperText,
	ViewAssociatedOrdersText,
	CarrierText,
	TruckNumberText,
	TrackingNumberText,
	EstimatedDeliveryToolTipDesc
} from "constants/text";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import InsertLink from "@mui/icons-material/InsertLink";
import { ORDER_STATUS_REASONS } from "constants/orderStatusHeaderStatusReasons";
import { dateFormat } from "utils/date";
import { convertToTitleCase } from "utils/string";
import { useNavigate } from "react-router-dom";
import { brandCheck, displayDesigner } from "utils/order";
import ExportSnackbar from "../ExportFile/ExportSnackbar";
import ExportButtons from "../ExportFile/ExportButtons";
import useOrderDetailsPDFGenerator from "hooks/useOrderDetailsPDFGenerator";
import { OrderDetailViewModel, OrderStatus, OrderType } from "data/api/v1";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import { generateMyOrderDetailsExcel } from "./helpers/MyOrderDetailsExportsHelper";
import { useGetUserDetailsQuery } from "features/userApi";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import {
	AssociatedOrdersText,
	DrawerContainer,
	NumberOfOrders,
	OrderETA,
	ReplacementOrderSubmittedBanner,
	StyledChip,
	OrderDetailsMyOrdersLink,
	OrderDetailsHeaderWrapper,
	OrderDetailsHeaderContent,
	OrderDetailsHeaderContentDetails,
	OrderDetailsHeaderJobName,
	OrderDetailsBrandAccountInfo,
	OrderDetailsGrayBox,
	EstimatedDeliveryToolTip,
	EstimatedDelivery
} from "./OrderDetailsHeader.styles";
import { useGetLinkedOrdersQuery } from "features/orderApi";
import FlyoutHeader from "./AssociatedOrders/FlyoutHeader";
import IndividualLinkedOrderCard from "./AssociatedOrders/IndividualLinkedOrderCard";
import EmptyErrorStates from "./AssociatedOrders/EmptyErrorStates";
import { ShoppingCartFlyoutScrollableWrapper } from "../Replacements/ShoppingCartFlyout.styles";
import useWindowSettings from "hooks/useWindowSettings";
import Link from "components/Common/Link/Link";
import Tooltip from "@mui/material/Tooltip";
import useSnackbarEffect from "hooks/useSnackbarEffect";

const OrderDetailsHeader = ({ orderDetail }: Props) => {
	const formattedOrderType = convertToTitleCase(orderDetail.orderType);
	const [statusReasonMessage, setStatusReasonMessage] = useState("");
	const [statusReason, setStatusReason] = useState("");
	const [defaultColor, setNewColor] = useState("");
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const navigateTo = useNavigate();
	const { data: linkedOrders, error } = useGetLinkedOrdersQuery(orderDetail?.orderId ?? "");
	const linkedOrdersCount = linkedOrders?.length ? linkedOrders.length : 0;
	const { isMobile } = useWindowSettings();

	const replacementOrders: string[] = useSelector((state: RootState) => state.submittedReplacementOrders.orders);
	const hasReplacementOrder = useMemo(() => {
		return replacementOrders.find((originalOrderId) => originalOrderId === orderDetail.orderId);
	}, [replacementOrders, orderDetail.orderId]);

	const { pdfData, generateOrderDetailsPDF } = useOrderDetailsPDFGenerator(orderDetail);
	const { snackbarOpen } = useSnackbarEffect(pdfData.loading);

	const handleAssociatedOrders = () => {
		setIsOpen((prevState) => !prevState);
	};

	const handleOnClose = () => {
		setIsOpen((prevState) => !prevState);
	};

	const showEstimatedDeliveryText = (orderStatus?: string) => {
		return (
			orderStatus !== OrderStatus.DELIVERED &&
			orderStatus !== OrderStatus.CANCELLED &&
			orderStatus !== OrderStatus.INVOICED
		);
	};

	const orderDetailsHeaderStatusUpdate = orderDetail.statusUpdateDate
		? orderDetail.statusUpdateDate
		: orderDetail.updatedAt;

	useEffect(() => {
		ORDER_STATUS_REASONS.forEach(
			(reason) => reason.status === orderDetail.status && setStatusReasonMessage(reason.description)
		);

		ORDER_STATUS_REASONS.forEach(
			(reason) => reason.status === orderDetail.status && setStatusReason(reason.formattedStatus)
		);

		ORDER_STATUS_REASONS.forEach(
			(reason) => reason.status === orderDetail.status && setNewColor(reason.statusColor)
		);
	});

	const handleOrderReplacements = () => {
		return navigateTo(`/replacements/${orderDetail.orderId}`);
	};

	const { data: userDetails } = useGetUserDetailsQuery();
	const userAccountInformation = useMemo(() => {
		const accountMatch = userDetails?.user?.accounts?.find(
			(account: any) => account.number === orderDetail?.accountNumber
		);
		if (accountMatch) {
			return `${accountMatch.number}` + (accountMatch.description ? ` - ${accountMatch.description}` : "");
		} else {
			return "";
		}
	}, [userDetails, orderDetail]);

	const goToOrderDetails = (orderId: string) => {
		setIsOpen(false);
		return navigateTo(`/details/${orderId}`);
	};

	const orderUserAccountDetail = userDetails?.user?.accounts?.find(
		(account) => account.number === orderDetail.accountNumber
	);
	const isReplacementEligible =
		orderDetail.isReplacementEligible && (orderUserAccountDetail?.isActive || userDetails?.user?.role === "CARE");

	const carrierInfo: { label: string; info: string | null | undefined; dataTestId: string }[] = [
		{
			label: CarrierText,
			info: orderDetail.shippingDetails?.carrierName,
			dataTestId: "orderDetailsHeader-carrierName"
		},
		{
			label: TruckNumberText,
			info: orderDetail.shippingDetails?.truckNumber,
			dataTestId: "orderDetailsHeader-truckNumber"
		},
		{
			label: TrackingNumberText,
			info: orderDetail.shippingDetails?.trackingNumber,
			dataTestId: "orderDetailsHeader-trackingNumber"
		}
	];

	return (
		<OrderDetailsHeaderWrapper>
			<OrderDetailsMyOrdersLink>
				<Link
					to="/"
					hasBackIcon
					id="order-details-my-orders-link"
					data-id="order-details-my-orders-link"
					data-testid="orderDetailsHeader-myOrdersLink"
				>
					{myOrdersLinkText}
				</Link>
				<ExportButtons
					largePDF={false}
					generatePDF={generateOrderDetailsPDF}
					buttonHeader={exportOrderDetailsText}
					generateExcel={() => generateMyOrderDetailsExcel(orderDetail)}
				/>
				<ExportSnackbar
					open={snackbarOpen}
					fileLoading={pdfData.loading}
					largePDF={false}
				/>
			</OrderDetailsMyOrdersLink>
			<OrderDetailsHeaderContent>
				<OrderDetailsHeaderContentDetails>
					<OrderDetailsHeaderJobName>
						<h4
							data-testid="order-details-header-job-name"
							lang="en"
						>
							{orderDetail.jobName ? orderDetail.jobName : orderDetail.poNumber}
						</h4>

						<StyledChip
							label={formattedOrderType}
							data-testid="orderDetailsHeader-orderTypeChip"
						/>
					</OrderDetailsHeaderJobName>

					<OrderDetailsBrandAccountInfo>
						<StyledSummaryCardLogo
							data-testid="orderDetailsHeader-brandLogo"
							parentBrand={orderDetail.parentBrand}
							src={
								brandCheck(orderDetail.parentBrand)
									? `/assets/manufacture_logos/${orderDetail.parentBrand}.png`
									: "/assets/tandem_logos/cwg_logo.png"
							}
							alt={
								brandCheck(orderDetail.parentBrand)
									? String(orderDetail.parentBrand)
									: "CabinetworksGroup Logo"
							}
						/>
						<p
							className="body2"
							data-testid="orderDetails-accountNumberColumn"
						>
							<b className="subtitle2">{AccountNumberText}</b>{" "}
							{(userAccountInformation.length ? userAccountInformation : orderDetail?.accountNumber) ||
								UnavailableDataPlaceholderText}
						</p>
						<p
							className="body2"
							data-testid="orderDetailsHeader-Designer"
						>
							<b className="subtitle2">{DesignerNumberText}</b>{" "}
							{displayDesigner(orderDetail?.designer) ?? UnavailableDataPlaceholderText}
						</p>
					</OrderDetailsBrandAccountInfo>
					{isReplacementEligible && (
						<div>
							<Button
								sx={{ marginTop: "16px" }}
								variant="contained"
								data-testid="order-replacements"
								data-id="order-replacements-button"
								onClick={() => handleOrderReplacements()}
								aria-label="order replacements"
							>
								{OrderReplacementsText}
							</Button>
						</div>
					)}
					<Button
						variant="text"
						onClick={handleAssociatedOrders}
						data-testid="associated-orders-link"
						aria-label="associated-orders-link"
						startIcon={
							<InsertLink
								aria-label="insert-link-icon"
								data-testid="insert-link-icon"
							/>
						}
						sx={{
							marginTop: "8px"
						}}
					>
						<AssociatedOrdersText>{ViewAssociatedOrdersText}</AssociatedOrdersText>
					</Button>
					<Drawer
						anchor="right"
						open={isOpen}
						onClose={handleOnClose}
					>
						<DrawerContainer>
							<FlyoutHeader handleOnClose={handleOnClose} />
							{linkedOrdersCount > 0 && (
								<>
									<Divider />
									<NumberOfOrders data-testid="number-of-linkedOrders">
										{linkedOrdersCount} {OrdersText}
									</NumberOfOrders>
								</>
							)}
							{error && (
								<EmptyErrorStates
									helperText={ErrorLinkedOrdersHelperText}
									image="/assets/UnauthorizedSadImage.svg"
									titleText={LinkedOrdersErrorHeaderText}
								/>
							)}

							{!error && linkedOrdersCount === 0 && (
								<EmptyErrorStates
									helperText={EmptyLinkedOrdersHelperText}
									image="/assets/NoOrdersSadBoxImage.svg"
									titleText={NoLinkedOrdersHeaderText}
								/>
							)}

							{linkedOrdersCount > 0 && (
								<ShoppingCartFlyoutScrollableWrapper
									isMobile={isMobile}
									isReplacementsPage={false}
									isDetailsPage
								>
									{linkedOrders?.map(
										(associatedOrder) =>
											associatedOrder && (
												<IndividualLinkedOrderCard
													key={associatedOrder.orderId}
													associatedOrder={associatedOrder}
													goToOrderDetails={goToOrderDetails}
												/>
											)
									)}
								</ShoppingCartFlyoutScrollableWrapper>
							)}
						</DrawerContainer>
					</Drawer>
				</OrderDetailsHeaderContentDetails>

				{orderDetail.orderType !== OrderType.BILLING_ONLY && (
					<OrderDetailsGrayBox statusReasonColor={defaultColor}>
						<p
							className="overline"
							data-testid="orderDetailsHeader-statusUpdatedDate"
						>
							Status Updated:{" "}
							{orderDetail.statusUpdateDate !== null || orderDetail.updatedAt !== null
								? dateFormat(String(orderDetailsHeaderStatusUpdate))
								: UnavailableDataPlaceholderText}
						</p>
						<div>
							<div>
								<h6 data-testid="orderDetailsHeader-status">{statusReason}</h6>
								<p data-testid="orderDetailsHeader-statusReasonMessage">{statusReasonMessage}</p>
							</div>
							{orderDetail.status !== OrderStatus.CANCELLED && (
								<div>
									{showEstimatedDeliveryText(orderDetail?.status) && (
										<EstimatedDelivery
											className="subtitle1"
											data-testid="orderDetailsHeader-estimatedDelivery"
										>
											{EstimatedDeliveryText}
											<Tooltip
												data-testid="orderDetailsHeader-estimatedDelivery-toolTip"
												title={EstimatedDeliveryToolTipDesc}
												placement="top"
											>
												<EstimatedDeliveryToolTip>
													<InfoOutlined data-testid="estimated-delivery-tooltip-icon" />
												</EstimatedDeliveryToolTip>
											</Tooltip>
										</EstimatedDelivery>
									)}
									{orderDetail.eta && (
										<OrderETA data-testid="orderDetailsHeader-eta">{orderDetail.eta}</OrderETA>
									)}
								</div>
							)}
						</div>
						<div>
							{carrierInfo.map(
								(info) =>
									info.info && (
										<p
											className="body2"
											data-testid={info.dataTestId}
											key={info.label}
										>
											<b className="subtitle2">{info.label}</b> {info.info}
										</p>
									)
							)}
						</div>
					</OrderDetailsGrayBox>
				)}
			</OrderDetailsHeaderContent>
			{hasReplacementOrder && (
				<ReplacementOrderSubmittedBanner data-testid="orderDetailsHeader-replacement-submitted-banner">
					<InfoOutlined />
					<div>
						<p>{ReplacementOrderSubmitted}</p>
						<p>{ReplacementOrderSubmittedText}</p>
					</div>
				</ReplacementOrderSubmittedBanner>
			)}
		</OrderDetailsHeaderWrapper>
	);
};

interface Props {
	orderDetail: OrderDetailViewModel;
}

export default OrderDetailsHeader;
