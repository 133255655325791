import ProgressStepper from "components/Common/Stepper/Stepper";
import {
	NewOrdersHeaderContainer,
	NewOrdersHeaderHead,
	NewOrdersHeaderRow,
	NewOrdersSummaryDetails
} from "../NewOrders.styles";
import {
	ItemsWillClearText,
	NoExistOrder,
	PlaceANewOrderText,
	SaveDraftBeforeLeavingText,
	StartOver,
	YesSaveDraft
} from "constants/text";
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { createViewPriceEstimates, getNewOrderSummaryDetailInfo } from "../utils/NewOrderUtils";
import {
	NewOrdersHeaderSummaryDetailsProductLines,
	NewOrdersHeaderSummaryDetailsCabinetsCount,
	NewOrdersHeaderSummaryDetailsAccessoriesCount,
	NewOrdersHeaderSummaryDetailsCubeCount,
	DraftSaveAndExit,
	DraftExitOrder,
	OrderReviewText,
	PriceEstimateText,
	NewOrderThanksHeader,
	ConfirmationText
} from "../constants";
import { LinkButton } from "../../Common/Link";
import LeaveFlowDialog from "../../Common/LeaveFlowDialog/LeaveFlowDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { newOrderActions } from "features/newOrder";
import { submittingNewOrderActions } from "features/submittingNewOrder";
import SaveDraftModal from "../SaveDraft/SaveDraftModal";
import { shippingDetailsActions } from "features/shippingDetails";
import ExportButtons from "components/ExportFile/ExportButtons";
import useNewOrdersPDFGenerator from "hooks/useNewOrdersPDFGenerator";
import ExportSnackbar from "components/ExportFile/ExportSnackbar";
import useSnackbarEffect from "hooks/useSnackbarEffect";

interface NewOrdersHeaderProps {
	getContainerElement?: (container: RefObject<HTMLDivElement>) => void;
	includeDetails?: boolean;
	includeStepper?: boolean;
	isReview?: boolean;
	isConfirmation?: boolean;
	isPDFExport?: boolean;
	isNewOrderPage?: boolean;
}

const NewOrdersHeader = ({
	getContainerElement,
	includeDetails = true,
	includeStepper = true,
	isReview = false,
	isConfirmation = false,
	isPDFExport = false,
	isNewOrderPage = false
}: NewOrdersHeaderProps) => {
	const dispatch = useDispatch();
	const containerElement = useRef<HTMLDivElement>(null);
	const location = useLocation();
	const navigate = useNavigate();
	const dialogState = useSelector((state: RootState) => state.newOrder.leaveNewOrderFlow);
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);
	const summaryDetails = useMemo(() => {
		return getNewOrderSummaryDetailInfo(parsedCSV, validatedOrderResponse);
	}, [parsedCSV, validatedOrderResponse]);

	const orderDetails = useSelector((state: RootState) => state.submittingNewOrder.reviewOrderDisplay);
	const priceEstimates = useMemo(() => {
		if (parsedCSV !== undefined && validatedOrderResponse !== undefined) {
			return createViewPriceEstimates(parsedCSV, validatedOrderResponse);
		}
	}, [parsedCSV, validatedOrderResponse]);

	let headerText: string;

	if (isConfirmation) {
		headerText = ConfirmationText;
	} else if (isReview) {
		headerText = OrderReviewText;
	} else {
		headerText = PriceEstimateText;
	}

	const { pdfData, generateNewOrdersPDF } = useNewOrdersPDFGenerator(
		priceEstimates?.configurations,
		summaryDetails,
		headerText,
		priceEstimates?.pricing.extendedPrice ?? 0,
		orderDetails,
		isReview,
		isConfirmation
	);
	const { snackbarOpen } = useSnackbarEffect(pdfData.loading);

	const handleStartOver = () => {
		if (parsedCSV) {
			dispatch(newOrderActions.leaveNewOrderFlow({ state: "pause" }));
		}
		if (!parsedCSV) {
			navigate("/new-order");
		}
	};

	const handleDialogExitOrder = () => {
		if (dialogState?.navItem) {
			dispatch(newOrderActions.continueNewOrderFlow());
		} else {
			dispatch(newOrderActions.resetNewOrderFlow());
			navigate("/new-order");
		}
		dispatch(newOrderActions.clearNewOrder());
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
		dispatch(shippingDetailsActions.clearShippingDetails());
	};

	const handleDialogClose = () => {
		dispatch(newOrderActions.resetNewOrderFlow());
	};

	useEffect(() => {
		if (getContainerElement) {
			return getContainerElement(containerElement);
		}
	}, [getContainerElement]);

	const [draftModalIsOpen, setDraftModalIsOpen] = useState(false);
	const handleOpenCloseSaveDraftModalToggle = () => {
		if (draftModalIsOpen) {
			handleDialogExitOrder();
		} else {
			setDraftModalIsOpen(true);
		}
	};

	return (
		<NewOrdersHeaderContainer
			data-testid="new-orders-header"
			ref={containerElement}
			isConfirmation={isConfirmation}
		>
			{location.pathname !== "/new-order" && (
				<NewOrdersHeaderHead>
					{isConfirmation ? (
						<h5>{NewOrderThanksHeader}</h5>
					) : (
						<div data-testid="new-orders-header-start-over">
							<LinkButton
								hasBackIcon
								onClick={handleStartOver}
								data-testid="new-order-start-over-link"
							>
								{StartOver}
							</LinkButton>
						</div>
					)}
					{isPDFExport && (
						<div>
							<ExportButtons
								largePDF={false}
								generatePDF={generateNewOrdersPDF}
								buttonHeader={"EXPORT " + headerText.toUpperCase()}
								isOnlyPDF
							/>
							<ExportSnackbar
								open={snackbarOpen}
								fileLoading={pdfData.loading}
								largePDF={false}
							/>
						</div>
					)}
				</NewOrdersHeaderHead>
			)}

			<LeaveFlowDialog
				firstButtonText={NoExistOrder}
				dialogState={dialogState !== undefined && !draftModalIsOpen}
				handleDialogExitOrder={handleDialogExitOrder}
				handleDialogClose={handleDialogClose}
				handleOpenDraftModal={handleOpenCloseSaveDraftModalToggle}
				secondButtonText={YesSaveDraft}
				subtitle={ItemsWillClearText}
				title={SaveDraftBeforeLeavingText}
			/>

			<SaveDraftModal
				saveDraftModalIsOpen={draftModalIsOpen}
				handleOpenCloseSaveDraftModalToggle={handleOpenCloseSaveDraftModalToggle}
				firstButtonText={DraftSaveAndExit}
				secondButtonText={DraftExitOrder}
			/>

			{!isConfirmation && (
				<NewOrdersHeaderRow data-testid="new-orders-header-heading">
					<h1 data-testid="new-orders-header-title">{PlaceANewOrderText}</h1>
					{includeStepper && <ProgressStepper data-testid="new-orders-header-stepper" />}
				</NewOrdersHeaderRow>
			)}
			{includeDetails && (
				<NewOrdersSummaryDetails
					data-testid="new-orders-header-summary-details"
					isNewOrderPage={isNewOrderPage}
				>
					<div>
						<div>{NewOrdersHeaderSummaryDetailsProductLines}</div>
						<div data-testid="new-orders-header-summary-details-product-lines">
							{summaryDetails.productLines}
						</div>
					</div>
					<div>
						<div>{NewOrdersHeaderSummaryDetailsCabinetsCount}</div>
						<div data-testid="new-orders-header-summary-details-cabinets-count">
							{summaryDetails.cabinetCount}
						</div>
					</div>
					<div>
						<div>{NewOrdersHeaderSummaryDetailsAccessoriesCount}</div>
						<div data-testid="new-orders-header-summary-details-accessories-count">
							{summaryDetails.accessoryCount}
						</div>
					</div>
					<div>
						<div>{NewOrdersHeaderSummaryDetailsCubeCount}</div>
						<div data-testid="new-orders-header-summary-details-cubes-count">
							{summaryDetails.cubeCount}
						</div>
					</div>
				</NewOrdersSummaryDetails>
			)}
		</NewOrdersHeaderContainer>
	);
};

export default NewOrdersHeader;
