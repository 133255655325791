import { createSlice } from "@reduxjs/toolkit";
import { read } from "hooks/useSession";
import { CreatePendingOrderRequest } from "data/api/v1/model/create-pending-order-request";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model";

const storageState = read("_submittingNewOrder");

const newOrder = storageState ? { ...storageState, newOrder: storageState?.newOrder ?? [] } : undefined;
interface NewOrderSlice {
	newOrder: CreatePendingOrderRequest | undefined;
	reviewOrderDisplay: CreatePendingOrderRequest | undefined;
	draftOrder: DraftOrderViewModel | undefined;
	draftError: any;
}

const initialState: NewOrderSlice = newOrder || {
	newOrder: undefined,
	shippingAddress: undefined,
	draftOrder: undefined,
	draftError: undefined
};

const submittingNewOrderSlice = createSlice({
	name: "submittingNewOrder",
	initialState,
	reducers: {
		submittingNewOrder: (state, action) => {
			state.newOrder = action.payload;
		},
		reviewOrderDisplay: (state, action) => {
			state.reviewOrderDisplay = action.payload;
		},
		clearOrderToBeSubmitted: (state) => {
			state.newOrder = undefined;
			state.draftOrder = undefined;
			state.draftError = undefined;
			state.reviewOrderDisplay = undefined;
		},
		updateDraftOrder: (state, action) => {
			state.draftOrder = action.payload;
		},
		updateDraftError: (state, action) => {
			state.draftError = action.payload;
		},
		clearDraftError: (state) => {
			state.draftError = undefined;
		},
		updateDraftName: (state, action) => {
			state.draftOrder = {
				...state.draftOrder,
				draftName: action.payload
			};
		}
	}
});

export const submittingNewOrderActions = submittingNewOrderSlice.actions;
export default submittingNewOrderSlice.reducer;
