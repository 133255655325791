import {
	NewOrdersLayoutContainer,
	NewOrdersContentContainer,
	NewOrdersContent,
	NewOrderConfirmationOrderListHeader,
	NewOrdersSummaryDetails
} from "components/NewOrders/NewOrders.styles";
import {
	NewOrdersHeaderSummaryDetailsAccessoriesCount,
	NewOrdersHeaderSummaryDetailsCabinetsCount,
	NewOrdersHeaderSummaryDetailsCubeCount,
	NewOrdersHeaderSummaryDetailsProductLines,
	NewOrderThanksSubheader,
	NewOrderButton,
	NewOrderConfirmationOrderNumber,
	NewOrderConfirmationOrderPending,
	NewOrderOrderDate,
	NewOrderNewOrderLabel
} from "components/NewOrders/constants";
import ReviewOrderDetails from "components/NewOrders/Review/ReviewOrderDetails";
import ReviewOrderList from "components/NewOrders/Review/ReviewOrderList";
import ConfirmationThanks from "components/Common/ConfirmationThanks/ConfirmationThanks";
import { ReplacementHeaderText } from "components/Replacements/ReplacementHeaderText.styles";
import { useMemo, useEffect } from "react";
import { todayDate, getNewOrderSummaryDetailInfo } from "components/NewOrders/utils/NewOrderUtils";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "stores/application.store";
import { newOrderActions } from "features/newOrder";
import { submittingNewOrderActions } from "features/submittingNewOrder";
import { dateFormat } from "utils/date";

const NewOrderConfirmation = () => {
	const dispatch = useDispatch();
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);
	const summaryDetails = useMemo(() => {
		return getNewOrderSummaryDetailInfo(parsedCSV, validatedOrderResponse);
	}, [parsedCSV, validatedOrderResponse]);

	useEffect(() => {
		window.onpopstate = function () {
			window.history.replaceState(null, "New Order Confirmation", "/new-order/confirmation");
			setTimeout(function () {
				window.location.replace("/new-order");
			}, 0);
			dispatch(newOrderActions.clearNewOrder());
			dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
		};
	}, [dispatch]);

	return (
		<NewOrdersLayoutContainer>
			<ConfirmationThanks
				subheader={NewOrderThanksSubheader}
				button={NewOrderButton}
			/>
			<NewOrdersContentContainer>
				<NewOrdersContent>
					<NewOrderConfirmationOrderListHeader>
						<div>{NewOrderNewOrderLabel}</div>
						<div>
							<span data-testid="new-order-confirmation-header">
								<ReplacementHeaderText data-testid="new-order-confirmation-order-number">
									{NewOrderConfirmationOrderNumber}
									<ReplacementHeaderText data-testid="new-order-confirmation-pending">
										&nbsp;
										{NewOrderConfirmationOrderPending}
									</ReplacementHeaderText>
								</ReplacementHeaderText>
							</span>

							<NewOrdersSummaryDetails
								data-testid="new-orders-header-summary-details"
								isConfirmation={true}
							>
								<div>
									<div>{NewOrderOrderDate}</div>
									<div data-testid="new-order-header-summary-details-order-date">
										{dateFormat(String(todayDate))}
									</div>
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsProductLines}</div>
									<div data-testid="new-order-header-summary-details-product-lines">
										{summaryDetails.productLines}
									</div>
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsCabinetsCount}</div>
									<div data-testid="new-order-header-summary-details-cabinets-count">
										{summaryDetails.cabinetCount}
									</div>
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsAccessoriesCount}</div>
									<div data-testid="new-order-header-summary-details-accessories-count">
										{summaryDetails.accessoryCount}
									</div>
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsCubeCount}</div>
									<div data-testid="new-order-header-summary-details-cubes-count">
										{summaryDetails.cubeCount}
									</div>
								</div>
							</NewOrdersSummaryDetails>
						</div>
					</NewOrderConfirmationOrderListHeader>
					<ReviewOrderDetails />
					<ReviewOrderList />
				</NewOrdersContent>
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default NewOrderConfirmation;
