import { OrderValidationViewModel } from "data/api/v1/model/order-validation-view-model";
import { ImportOrderViewModel } from "data/api/v1/model/import-order-view-model";
import {
	ViewPriceEstimatesBaseItem,
	ViewPriceEstimatesConfigurationModel,
	ViewPriceEstimatesLineItem,
	ViewPriceEstimatesModel
} from "../ViewPriceEstimates/view-price-estimates-model";
import { ImportConfigurationViewModel } from "data/api/v1";
import { ImportItemViewModel } from "data/api/v1/model/import-item-view-model";
import { UnavailableDataPlaceholderText } from "constants/text";
import { FileContents, newOrderActions } from "features/newOrder";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { NewOrderSummaryDetailModel } from "../NewOrdersHeader/new-order-summary-details-model";
import { UNIQUE_BRAND_NAMES } from "constants/uniqueBrandNames";

export const createViewPriceEstimates = (
	parsedCSV: ImportOrderViewModel,
	validatedOrderResponse: OrderValidationViewModel
): ViewPriceEstimatesModel => {
	// We need a way to aggregate data coming from the parsed csv and the api validate response
	// The top level needs to have pricing, counts, other info & then the melded configurations

	// Combining the configuration fields from the csv to the api
	const configs = parsedCSV?.configurations?.reduce(
		(
			configAccum: ViewPriceEstimatesConfigurationModel[],
			csvConfig: ImportConfigurationViewModel,
			index: number
		) => {
			if (csvConfig.items && csvConfig.items.length > 0) {
				// There is no distinct configuration id, so we need to make sure we are mapping the right one with its associated line items
				// The lineNumbers in a single csv file, regardless of configuration, always increment, so we will not have the same line number in a csv
				// Note: This may need to be revised if we upload multiple csv files, but should be able to distinguished based on file name
				const firstItem = csvConfig.items[0];
				const validationConfig = validatedOrderResponse?.configurations.find((c) => {
					return c.lineItems?.find((item) => item.lineNumber === firstItem.lineNumber);
				});

				if (validationConfig) {
					const { items, ...restOfCSVConfig } = csvConfig;
					const { lineItems, ...restOfValidationConfig } = validationConfig;

					// Inside each configuration (coming from csv and api), there are line items
					// CSV refers to them as items
					// API refers to them as lineItems
					// We need to meld the values from each of these together to create a new one
					const newItems = items.reduce(
						(lineItemAccum: ViewPriceEstimatesLineItem[], csvLineItem: ImportItemViewModel) => {
							const validationLineItem = lineItems?.find(
								(lineItem) => lineItem.lineNumber === csvLineItem.lineNumber
							);

							if (validationLineItem) {
								const { modifications, ...restOfCSVLineItem } = csvLineItem;

								// Inside each line item there are modifications (coming from csv & api)
								// These need to also be melded together to create a new modification
								const newModifications = modifications?.reduce(
									(modAccum: ViewPriceEstimatesBaseItem[], csvModification: ImportItemViewModel) => {
										const validationModification = validationLineItem.modifications.find(
											(mod) => mod.lineNumber === csvModification.lineNumber
										);

										if (validationModification) {
											const modification: ViewPriceEstimatesBaseItem = {
												...csvModification,
												...validationModification
											};
											modAccum.push(modification);
										}

										return modAccum;
									},
									[]
								);
								const lineItem: ViewPriceEstimatesLineItem = {
									...restOfCSVLineItem,
									...validationLineItem,
									modifications: newModifications ?? []
								};
								lineItemAccum.push(lineItem);
							}

							return lineItemAccum;
						},
						[]
					);

					const config: ViewPriceEstimatesConfigurationModel = {
						// Generating a unique configuration id, this may need to consider file name in future when multiple csv files are uploaded
						id: `configuration-${index + 1}-${firstItem.lineNumber}`,
						...restOfCSVConfig,
						...restOfValidationConfig,
						brand: csvConfig.brand ?? UnavailableDataPlaceholderText,
						parentBrand: csvConfig.parentBrand ?? UnavailableDataPlaceholderText,
						productLineCode: csvConfig.productLineCode ?? UnavailableDataPlaceholderText,
						items: newItems.sort((a, b) => Number(a.lineNumber) - Number(b.lineNumber))
					};
					configAccum.push(config);
				}
			}
			return configAccum;
		},
		[]
	);

	const { configurations, ...restOfValidatedOrder } = validatedOrderResponse || {};

	// Constructing view that combines top level information: pricing, counts, etc.
	// And the aggregated configurations data
	return {
		...restOfValidatedOrder,
		configurations: configs ?? []
	};
};

export const useCheckForBillToAccountErrors = (onSuccess: () => void) => {
	const csvContents: FileContents | undefined = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const dispatch = useDispatch();

	const checkForErrors = useCallback(() => {
		let canContinue = true;
		const configurations = csvContents?.configurations;
		if (configurations) {
			for (let i = 0; i < configurations.length; i++) {
				if (!configurations[i].accountNumber) {
					dispatch(newOrderActions.updateAccountNumber({ index: i, newAccountNumber: null }));
					canContinue = false;
				}
				if (!configurations[i].billToNumber && configurations[i].accountNumber) {
					dispatch(newOrderActions.updateBillToNumber({ index: i, newBillToNumber: null }));
					canContinue = false;
				}
			}
		}
		if (canContinue) {
			onSuccess();
		}
	}, [csvContents, dispatch, onSuccess]);

	return checkForErrors;
};

export const getNewOrderSummaryDetailInfo = (
	parsedCSV?: ImportOrderViewModel,
	validatedOrderResponse?: OrderValidationViewModel
): NewOrderSummaryDetailModel => {
	const brand = UNIQUE_BRAND_NAMES.find((i) =>
		parsedCSV?.configurations?.find((configuration) => i.input === configuration.parentBrand)
	);

	// Note: Javascript has issues with floating point arithmetic and makes entries like 1.015 multiplied by 100 to 101.49999999...
	// Adding the Number.EPSILON to the cube count before multiplying by 100 addresses this issue
	const cube100x = validatedOrderResponse ? (validatedOrderResponse.counts.cubeCount + Number.EPSILON) * 100 : 0;
	const roundedCube = Math.round(cube100x) / 100;
	return {
		productLines: brand?.output ?? UnavailableDataPlaceholderText,
		accessoryCount: validatedOrderResponse?.counts?.accessoryCount?.toString() ?? UnavailableDataPlaceholderText,
		cabinetCount: validatedOrderResponse?.counts?.cabinetCount?.toString() ?? UnavailableDataPlaceholderText,
		cubeCount: validatedOrderResponse?.counts?.cubeCount
			? roundedCube.toFixed(2)?.toString()
			: UnavailableDataPlaceholderText
	};
};
export const todayDate = new Date();
